import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { navigate } from 'gatsby';
import { EndSubSchema } from '../../services/validationSchema';
import { Form, Formik, Field, FieldProps, ErrorMessage } from 'formik';
import TextInput from '../molecules/textInput';
import Typography from '../atoms/typography';
import Button from '../atoms/button';
import Spacer from '../atoms/spacer';
import { ColorType } from '../atoms/interfaces';
import styled from 'styled-components';
import { endSubscription, submitNetlifyForm } from '../../api';
import {
  endSubForm,
  EndSubscriptionType,
  Forms,
  getSubscriptionTypeString
} from '../../services/formData';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import useSnackbar from '../../hooks/useSnackbar';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import Routes from '../../services/routes';
import {
  PAYMENT_TYPE,
  resetPaymentDetails,
  savePaymentType
} from '../../utils/localStorage';
import HoneyPotHidden from '../HoneyPotHidden';

interface IFormWrapper {
  backgroundColor: ColorType;
}

interface IEndSubscriptionForm {
  backgroundColor: ColorType;
}

interface IEndSubValues {
  selected: string | undefined;
  isFormValid: boolean;
}

const initialFormValues: IEndSubValues = {
  selected: undefined,
  isFormValid: false
};

const HeaderAmount = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
`;
const HeaderDescription = styled(Typography)`
  font-size: 24px;
`;

const ConfirmEndText = styled(Typography)`
  font-weight: 700;
`;

const FormWrapper = styled.div<IFormWrapper>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.textPrimary};
`;

const EndSubscriptionForm: React.FC<IEndSubscriptionForm> = ({
  backgroundColor = 'textPrimary'
}) => {
  const [loading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isTerminated, setSubTerminated] = useState(false);
  const { handleMessage } = useSnackbar();
  const { IS_MOBILE } = useScreenClassHelper();
  const { storageAmount, productAmount, fetchData, user } =
    useFirebaseContext();

  const handleSubmit = async () => {
    setFormSubmitted(true);
  };

  const goBack = (resetForm: any) => {
    resetForm();
    setFormSubmitted(false);
  };

  const confirmSubmit = async (value: EndSubscriptionType) => {
    if (value === EndSubscriptionType.ORDER_HOME) {
      resetPaymentDetails();
      savePaymentType(PAYMENT_TYPE.END_SUBSCRIPTION);
      navigate(Routes.ORDER_HOME_PAYMENT, {
        state: {
          orderAmount: productAmount
        }
      });
    } else if (user?.ssn) {
      setIsLoading(true);
      try {
        const res = await endSubscription(
          user.ssn,
          value === EndSubscriptionType.JUST_END_EMPTY
        );
        if (res.statusCode !== 200) {
          handleMessage(
            'Vi kunde tyvärr inte avsluta ditt abbonnemang, vänligen kontakta support'
          );
          return;
        }
        const success = await submitNetlifyForm(Forms.END_SUBSCRIPTION, {
          email: user?.email,
          name: `${user?.firstName} ${user?.lastName}`,
          phone: user?.phone,
          lagerplatser: storageAmount,
          panbox: productAmount,
          message: getSubscriptionTypeString(value)
        });
        if (success) {
          fetchData();
          setSubTerminated(true);
          setIsLoading(false);
        } else {
          handleMessage(
            'Vi kunde tyvärr inte avsluta ditt abbonnemang, vänligen kontakta support'
          );
        }
      } catch (e) {
        handleMessage(
          'Vi kunde tyvärr inte avsluta ditt abbonnemang, vänligen kontakta support'
        );
      }
    }
  };

  if (isTerminated) {
    return (
      <Row>
        <Col md={6}>
          <Typography variant="h3" color="primary">
            Avsluta panBox
          </Typography>
          <Spacer />
          <ConfirmEndText>Du har nu sagt upp din panBox</ConfirmEndText>
          <Spacer />
          <Typography>Du kan när som helst beställa en ny panBox</Typography>
          <Spacer />
          <Button
            isMobile={IS_MOBILE}
            primary
            onClick={() => navigate(Routes.DASHBOARD)}
          >
            Klar
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={EndSubSchema}
      onSubmit={handleSubmit}
    >
      {({ resetForm, values, setFieldValue }) => (
        <Form
          data-netlify="true"
          action="/"
          name={Forms.END_SUBSCRIPTION}
          netlify-honeypot="bot-field"
        >
          <input
            type="hidden"
            name="form-name"
            value={Forms.END_SUBSCRIPTION}
          />
          <HoneyPotHidden />
          <FormWrapper backgroundColor={backgroundColor}>
            <Typography variant="h3" color="primary">
              Avsluta panBox
            </Typography>
            {storageAmount > 0 && (
              <>
                <Spacer />
                {!formSubmitted ? (
                  <HeaderDescription variant="span">
                    Du har{' '}
                    <HeaderAmount color="error" variant="span">
                      {productAmount} st
                    </HeaderAmount>{' '}
                    panBox.
                  </HeaderDescription>
                ) : (
                  <Typography>
                    Är du helt säker på att du vill avsluta panBox?
                  </Typography>
                )}
                <Spacer />
                <Row>
                  {!formSubmitted && (
                    <>
                      {endSubForm
                        .filter(
                          (item) =>
                            !(
                              ((item.name === EndSubscriptionType.ORDER_HOME ||
                                item.name === EndSubscriptionType.JUST_END) &&
                                productAmount === 0) ||
                              (item.name ===
                                EndSubscriptionType.JUST_END_EMPTY &&
                                productAmount >= storageAmount)
                            )
                        )
                        .map((item) => (
                          <Col key={item.name} md={12} sm={12}>
                            <Spacer />
                            <Field name={item.name}>
                              {({ field }: FieldProps) => (
                                <TextInput
                                  {...field}
                                  {...item}
                                  checked={item.name === values.selected}
                                  onChange={() =>
                                    setFieldValue('selected', item.name)
                                  }
                                />
                              )}
                            </Field>
                          </Col>
                        ))}
                    </>
                  )}
                  <Col md={4} sm={4}>
                    <Typography variant="error">
                      <ErrorMessage name="selected" />
                    </Typography>

                    {formSubmitted ? (
                      <>
                        <Button
                          isMobile={IS_MOBILE}
                          onClick={() =>
                            confirmSubmit(
                              EndSubscriptionType[
                                values.selected ??
                                  EndSubscriptionType.ORDER_HOME
                              ]
                            )
                          }
                        >
                          Ja, avsluta panBox
                        </Button>
                        <Spacer />
                        <Button
                          isMobile={IS_MOBILE}
                          primary
                          onClick={() => goBack(resetForm)}
                        >
                          Nej, ta mig tillbaka
                        </Button>
                      </>
                    ) : (
                      <>
                        <Spacer />
                        <Button
                          isMobile={IS_MOBILE}
                          isLoading={loading}
                          type="submit"
                        >
                          Avsluta
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default EndSubscriptionForm;
