import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import Routes from '../services/routes';
import firebase from 'gatsby-plugin-firebase';

interface IPrivateRoute extends RouteComponentProps {
  component: React.ComponentType;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component: Component,
  location,
  ...props
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else if (!user && location?.pathname !== Routes.LANDING_PAGE) {
        navigate(Routes.LANDING_PAGE);
      }
    });
    return () => unsubscribe();
  }, [location?.pathname]);

  return isLoggedIn ? <Component {...props} /> : null;
};
export default PrivateRoute;
