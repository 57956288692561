import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import DrawerListItem from '../molecules/drawerListItem';
import { navigate } from 'gatsby';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import close from '../../images/icons/close_white.png';
import { useEffect } from 'react';
import Routes from '../../services/routes';
import { useLocation } from '@reach/router';
import firebase from 'gatsby-plugin-firebase';
import useSnackbar from '../../hooks/useSnackbar';
import logoutIcon from '../../images/icons/drawer-logout.png';
import Divider from '../atoms/divider';
import useFirebaseContext from '../../hooks/useFirebaseContext';

export interface IDrawerLinkItem {
  id?: number;
  icon: string;
  title: string;
  route?: string;
}

interface INavigationDrawer {
  linkItems: IDrawerLinkItem[];
  logo: string;
  closeDrawer: () => void;
}

const Container = styled.div`
  position: relative;
  padding: 0rem 2rem;
  background-color: ${(props) => props.theme.colors.drawerBackground};
  box-sizing: border-box;
  height: 100%;
  ${(props) =>
    props.isMobile &&
    css`
      padding: 2rem 2rem;
      height: 100vh;
    `}
`;

const CloseIcon = styled.img`
  height: 25px;
`;

const NavigationDrawer: React.FC<INavigationDrawer> = ({
  linkItems,
  closeDrawer
}) => {
  const [activeListItemId, setActiveListItemId] = useState(0);
  const { IS_MOBILE } = useScreenClassHelper();
  const location = useLocation();
  const { handleMessage } = useSnackbar();
  const { clearData } = useFirebaseContext();

  useEffect(() => {
    switch (window?.location?.pathname) {
      case Routes.DASHBOARD:
        setActiveListItemId(0);
        break;
      case Routes.MY_DETAILS:
        setActiveListItemId(1);
        break;
      case Routes.PAYMENT_METHOD:
        setActiveListItemId(2);
        break;
      case Routes.ORDER_HOME:
        setActiveListItemId(3);
        break;
    }
  }, [location?.pathname]);

  const handleItemClick = (item: IDrawerLinkItem) => {
    if (item.route) {
      navigate(item.route);
    }
  };

  const handleSignOut = async () => {
    try {
      clearData();
      await firebase.auth().signOut();
      localStorage.clear();
      navigate(Routes.LANDING_PAGE);
    } catch (error) {
      handleMessage(
        'Kunde inte logga ut, vänligen försök igen eller kontakta support.'
      );
    }
  };

  return (
    <Container isMobile={IS_MOBILE}>
      {IS_MOBILE ? (
        <>
          <CloseIcon alt="stäng" onClick={closeDrawer} src={close} />
          <Spacer spacing={4} />
        </>
      ) : (
        <>
          <Spacer spacing={10} />
          <Typography color="textSecondary" variant="h3">
            Mina sidor
          </Typography>
          <Spacer spacing={3} />
        </>
      )}

      {linkItems.map((item) => (
        <React.Fragment key={item.id}>
          <DrawerListItem
            {...item}
            isActive={item.id === activeListItemId}
            onClick={() => handleItemClick(item)}
          />
          <Spacer />
        </React.Fragment>
      ))}
      <Spacer />
      <Divider color="placeholderPrimary" />
      <Spacer spacing={2} />
      <DrawerListItem
        icon={logoutIcon}
        title="Logga ut"
        onClick={handleSignOut}
      />
    </Container>
  );
};

export default NavigationDrawer;
