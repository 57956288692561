import React, { useState } from 'react';
import { Col, Row, Container } from 'react-grid-system';
import { navigate } from 'gatsby';
import Typography from '../../atoms/typography';
import Spacer from '../../atoms/spacer';
import Button from '../../atoms/button';
import Icon from '../../atoms/icon';
import * as Styled from './style';
import Modal from '../../molecules/modal';

import panbox from '../../../images/panbox.png';
import plus from '../../../images/icons/plus.png';
import minus from '../../../images/icons/minus.png';
import Routes from '../../../services/routes';
import useSnackbar from '../../../hooks/useSnackbar';
import useScreenClassHelper from '../../../hooks/useScreenClassHelper';
import useFirebaseContext from '../../../hooks/useFirebaseContext';
import {
  PAYMENT_TYPE,
  resetPaymentDetails,
  savePaymentType
} from '../../../utils/localStorage';

const OrderHome: React.FC = () => {
  const { handleMessage } = useSnackbar();
  const [orderAmount, setNewAmount] = useState(1);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const { IS_MOBILE } = useScreenClassHelper();
  const { productAmount, user, address } = useFirebaseContext();

  const add = () => {
    if (orderAmount !== productAmount) {
      setNewAmount(orderAmount + 1);
    }
  };
  const remove = () => {
    if (orderAmount > 1) {
      setNewAmount(orderAmount - 1);
    }
  };

  const onOrderHomeClicked = () => {
    if (productAmount === 0) {
      handleMessage(
        'Du har inga panBox att skicka hem. Gå till "Min panBox" för att beställa hem din panBox.'
      );
      return;
    }
    setShowRenewModal(true);
  };

  const handleRestock = (isRestock: boolean = false) => {
    resetPaymentDetails();
    savePaymentType(
      isRestock ? PAYMENT_TYPE.ORDER_HOME_RESTOCK : PAYMENT_TYPE.ORDER_HOME
    );
    navigate(Routes.ORDER_HOME_PAYMENT, {
      state: {
        orderAmount
      }
    });
    setShowRenewModal(false);
  };

  const HomeOrderSection: React.FC = () => {
    if (address) {
      return (
        <Row>
          <Col md={6}>
            <Spacer spacing={2} />
            <Styled.CustomRow>
              <Styled.CustomIconRow onClick={add}>
                <Icon width={25} src={plus} alt="plus" />
                <Spacer orientation="horizontal" />
                <Styled.BoldText>Lägg till</Styled.BoldText>
              </Styled.CustomIconRow>
              <Styled.CustomIconRow onClick={remove}>
                <Icon width={25} src={minus} alt="minus" />
                <Spacer orientation="horizontal" />
                <Styled.BoldText>Ta bort</Styled.BoldText>
              </Styled.CustomIconRow>
            </Styled.CustomRow>
            <Styled.StyledCard>
              <Styled.CardHeader>
                <Styled.BoldText>Antal panBox som skickas:</Styled.BoldText>
                <Typography color="primary" variant="h3">
                  {orderAmount} st
                </Typography>
              </Styled.CardHeader>
              <Spacer spacing={2} />
              <Styled.BoldText>
                Address som panBox skickas till:
              </Styled.BoldText>
              <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
              <Typography>{address.streetAddress}</Typography>
              <Typography>{`${address.zipCode} ${address.city}`}</Typography>
              <Row>
                <Col md={6}>
                  <Spacer />
                  <Button onClick={() => navigate(Routes.MY_DETAILS)}>
                    Ändra adress
                  </Button>
                </Col>
              </Row>
            </Styled.StyledCard>
          </Col>
          <Col md={6}>
            <Styled.Image src={panbox} alt="panBox" />
            <Styled.ButtonOrder
              isMobile={IS_MOBILE}
              primary
              onClick={onOrderHomeClicked}
            >
              Beställ hem nu
            </Styled.ButtonOrder>
            <Spacer spacing={4} />
          </Col>
        </Row>
      );
    } else
      return (
        <Row>
          <Col md={12}>
            <Spacer spacing={2} />
            <Typography variant="h3" color="textPrimary">
              Vänligen uppdatera dina adressupgifter för att kunna gå vidare
            </Typography>
          </Col>
          <Col md={6}>
            <Spacer />
            <Button onClick={() => navigate(Routes.MY_DETAILS)}>
              Uppdatera adress
            </Button>
          </Col>
        </Row>
      );
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <Typography color="primary" variant="h3">
              Beställ hem min panBox
            </Typography>
            <Spacer />
            <Styled.HeaderDescription variant="span">
              Du har{' '}
              <Styled.HeaderAmount color="error" variant="span">
                {productAmount} st
              </Styled.HeaderAmount>{' '}
              panBox du kan beställa hem.
            </Styled.HeaderDescription>
          </Col>
        </Row>
        {productAmount > 0 ? (
          <HomeOrderSection />
        ) : (
          <>
            <Spacer />
            <Typography>
              Gå till &quot;Min panBox&quot; för att beställa din panBox
            </Typography>
          </>
        )}
      </Container>
      {showRenewModal && (
        <Modal
          title="Vill du att vi fyller på ditt beredskapslager?"
          primaryButtonProps={{
            title: 'Ja tack!',
            onClick: () => handleRestock(true)
          }}
          secondaryButtonProps={{
            title: 'Nej tack!',
            onClick: () => handleRestock()
          }}
        />
      )}
    </>
  );
};

export default OrderHome;
