import styled, { css } from 'styled-components';
import Typography from '../../atoms/typography';
import Card from '../../atoms/card';
import Button from '../../atoms/button';

interface IMobile {
  isMobile: boolean;
}

export const Image = styled.img`
  width: 100%;
  margin: auto;
`;

export const CustomRow = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const CustomIconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
`;

export const BoldText = styled(Typography)`
  font-weight: bold;
`;

export const HeaderAmount = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
`;

export const HeaderDescription = styled(Typography)`
  font-size: 24px;
`;

export const StyledCard = styled(Card)`
  padding: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const ButtonOrder = styled(Button)<IMobile>`
  margin-left: auto;
  margin-right: 15%;
  display: block;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-right: auto;
    `}
`;
