import React from 'react';
import styled from 'styled-components';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import HamburgerMenu from '../atoms/hamburgerMenu';
import { Container } from 'react-grid-system';
export interface IDrawerLinkItem {
  id: number;
  icon: string;
  title: string;
  route: string;
}

interface INavigationDrawer {
  onClick: () => void;
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainer = styled(Container)`
  background-color: ${(props) => props.theme.colors.error};
  margin-bottom: 1rem;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const MyPagesMenu: React.FC<INavigationDrawer> = ({ onClick }) => {
  return (
    <StyledContainer>
      <Spacer spacing={8} />
      <StyledRow>
        <Typography color="textSecondary" variant="h3">
          Mina sidor
        </Typography>
        <StyledDiv onClick={onClick}>
          <Typography variant="span" color="textSecondary">
            Meny
          </Typography>
          <HamburgerMenu color="white" />
        </StyledDiv>
      </StyledRow>
      <Spacer spacing={1} />
    </StyledContainer>
  );
};

export default MyPagesMenu;
