import React, { useState, useEffect, useMemo } from 'react';
import HeroBanner from '../../components/organisms/heroBanner';
import Typography from '../../components/atoms/typography';
import Spacer from '../../components/atoms/spacer';
import Card from '../../components/atoms/card';
import Divider from '../../components/atoms/divider';
import styled from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../../services/sizeHelper';
import Container from '../atoms/container';
import { Row, Col } from 'react-grid-system';
import Prices, {
  formatPrice,
  getDetailedInfo,
  getOrderHomeTotalPrice
} from '../../services/prices';
import { useLocation } from '@reach/router';
import { SEAMLESS_VIEW_ID } from '../../constants';
import {
  getPaymentType,
  PAYMENT_TYPE,
  savePanboxOrderAmount
} from '../../utils/localStorage';
import usePayment from '../../hooks/usePayment';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import { navigate } from 'gatsby-link';
import Routes from '../../services/routes';

const Wrapper = styled.div`
  min-height: ${FULL_PAGE_HEIGHT};
  display: flex;
  flex-direction: column;
`;

const ContainerInner = styled(Container)`
  flex: 1;
`;

const StyledCard = styled(Card)`
  padding: 1.5rem;
`;

const TypoBold = styled(Typography)`
  font-weight: bold;
`;

const ContainerSeamless = styled.div`
  padding: 1rem;
  border-radius: 5px;
  background-color: white;
`;

const OrderHomePayment: React.FC = () => {
  const [orderAmount, setOrderAmount] = useState(0);
  const location = useLocation();
  const { initiatePayment, startPayment } = usePayment();
  const { storageAmount, productAmount } = useFirebaseContext();
  const [totalPrice, setTotalPrice] = useState(0);

  const isRestock = useMemo(() => {
    const paymentType = getPaymentType();
    return paymentType === PAYMENT_TYPE.ORDER_HOME_RESTOCK;
  }, []);

  useEffect(() => {
    if (
      typeof isRestock === 'undefined' ||
      productAmount === 0 ||
      storageAmount === 0 ||
      typeof location?.state?.orderAmount === 'undefined'
    ) {
      navigate(Routes.LANDING_PAGE);
      return;
    }

    const orderAmount = location.state.orderAmount;

    savePanboxOrderAmount(isRestock ? orderAmount : -orderAmount);
    setOrderAmount(orderAmount);

    const price = getOrderHomeTotalPrice({
      orderAmount,
      storageAmount,
      productAmount,
      isRestock
    });
    setTotalPrice(price);

    initialisePayment(price, isRestock ? orderAmount : -orderAmount);
  }, [location?.state, productAmount, storageAmount]);

  const initialisePayment = async (price: number, orderAmount: number) => {
    const res = await initiatePayment({
      amount: price * 100,
      numberOfPanBoxes: isRestock ? 0 : orderAmount
    });

    if (res) {
      startPayment();
    }
  };

  const detailedInfo = useMemo(
    () =>
      getDetailedInfo(orderAmount, storageAmount - orderAmount - productAmount),
    [orderAmount, storageAmount, productAmount]
  );

  return (
    <Wrapper>
      <HeroBanner
        leftPane={
          <>
            <Spacer spacing={6} />
            <Typography variant="header" color="textSecondary">
              Beställ hem panBox
            </Typography>
            <Spacer spacing={3} />
          </>
        }
      />
      <ContainerInner>
        <Row justify="center">
          <Col md={8}>
            <StyledCard>
              <Typography variant="h3">Kostnad</Typography>
              <Spacer spacing={2} />
              <Row>
                <Col>
                  <Typography>Frakt DHL/panBox</Typography>
                </Col>
                <Col xs="content">
                  <TypoBold color="primary">
                    {formatPrice(Prices.SHIPPING * orderAmount)}
                  </TypoBold>
                </Col>
              </Row>
              {isRestock && (
                <>
                  <Spacer />
                  <Row>
                    <Col>
                      <Typography>{detailedInfo.title}</Typography>
                    </Col>
                    <Col xs="content">
                      <TypoBold color="primary">
                        {formatPrice(detailedInfo.price ?? 0)}
                      </TypoBold>
                    </Col>
                  </Row>
                </>
              )}
              <Spacer />
              <Divider color="primary" />
              <Spacer />
              <Row>
                <Col>
                  <TypoBold>Totalt:</TypoBold>
                </Col>
                <Col xs="content">
                  <TypoBold color="primary">{formatPrice(totalPrice)}</TypoBold>
                </Col>
              </Row>
            </StyledCard>
            <Spacer spacing={2} />
            <ContainerSeamless>
              <div id={SEAMLESS_VIEW_ID} />
            </ContainerSeamless>
          </Col>
        </Row>
      </ContainerInner>
      <Spacer spacing={3} />
    </Wrapper>
  );
};

export default OrderHomePayment;
