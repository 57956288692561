import React from 'react';
import styled, { css } from 'styled-components';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import { IDrawerLinkItem } from '../organisms/navigationDrawer';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';

interface IDrawerListItem extends IDrawerLinkItem {
  isActive?: boolean;
  onClick: () => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.img<{ isActive: boolean }>`
  height: 2rem;
  ${(props) =>
    !props.isActive &&
    css`
      filter: grayscale(100%);
    `}
`;

const StyledTypography = styled(Typography)<{ isActive: boolean }>`
  ${(props) =>
    !props.isActive &&
    css`
      font-weight: normal;
    `}
`;

const DrawerListItem: React.FC<IDrawerListItem> = ({
  icon,
  title,
  isActive = false,
  onClick
}) => {
  const { IS_MOBILE } = useScreenClassHelper();

  if (IS_MOBILE) {
    return (
      <Container onClick={onClick}>
        <StyledTypography
          variant="h4"
          color="textSecondary"
          isActive={isActive}
        >
          {title}
        </StyledTypography>
        <Spacer orientation="horizontal" />
        <Icon alt="meny ikon" isActive={isActive} src={icon} />
      </Container>
    );
  }

  return (
    <Container onClick={onClick}>
      <Icon alt="meny ikon" size={20} isActive={isActive} src={icon} />
      <Spacer orientation="horizontal" />
      <StyledTypography variant="h4" color="textSecondary" isActive={isActive}>
        {title}
      </StyledTypography>
    </Container>
  );
};

export default DrawerListItem;
