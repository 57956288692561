import React, { useState, useEffect } from 'react';
import Typography from '../atoms/typography';
import { Col, Row, Container } from 'react-grid-system';
import MyDetailsForm from '../organisms/myDetails';
import Spacer from '../atoms/spacer';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import useSnackbar from '../../hooks/useSnackbar';
import { submitDetailsForm } from '../../api';
import { IFirebaseData } from '../../context/FirebaseContext';

export interface IMyDetailsFormValues {
  ssn: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  streetAddress: string;
  streetAddress2?: string;
  postalCode: string;
  city: string;
}

const initialFormValues: IMyDetailsFormValues = {
  ssn: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  streetAddress: '',
  streetAddress2: '',
  postalCode: '',
  city: ''
};

const MyDetails: React.FC = () => {
  const [formData, setFormData] = useState(initialFormValues);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const firebaseContext = useFirebaseContext();
  const { handleMessage } = useSnackbar();

  const setInitialFormData = (context: IFirebaseData) => {
    const userDoc = context.user;
    const addressDoc = context.address;
    const tempData: IMyDetailsFormValues = {
      ssn: userDoc?.ssn || '',
      firstName: userDoc?.firstName || '',
      lastName: userDoc?.lastName || '',
      email: userDoc?.email || '',
      phone: userDoc?.phone || '',
      city: addressDoc?.city ?? '',
      streetAddress: addressDoc?.streetAddress ?? '',
      streetAddress2: '',
      postalCode: addressDoc?.zipCode ?? ''
    };
    setFormData(tempData);
  };

  const onSubmit = async (values: IMyDetailsFormValues) => {
    try {
      if (!firebaseContext.user?.ssn) throw Error;
      setIsLoadingData(true);
      const userInfo = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone
      };
      const addressInfo = {
        streetAddress: values.streetAddress,
        zipCode: values.postalCode,
        city: values.city
      };
      await submitDetailsForm(
        firebaseContext?.user?.ssn,
        userInfo,
        addressInfo
      );
      firebaseContext.address = addressInfo;
      firebaseContext.user = {
        ...firebaseContext.user,
        ...userInfo
      };
      handleMessage('Dina uppgifter är sparade');
      setIsLoadingData(false);
    } catch (e) {
      handleMessage(
        'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
      );
      setIsLoadingData(false);
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      try {
        setInitialFormData(firebaseContext);
      } catch (e) {
        handleMessage(
          'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
        );
      }
    };
    fetchData();
  }, [firebaseContext]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Typography variant="h3" color="primary">
            Mina uppgifter
          </Typography>
          <Spacer spacing={2} />
        </Col>
        <Col md={11}>
          <MyDetailsForm
            onSubmit={onSubmit}
            loadingData={isLoadingData}
            formData={formData}
            ctaTitle="Spara"
            backgroundColor="formBackground"
          />
          <Spacer spacing={2} />
        </Col>
      </Row>
    </Container>
  );
};

export default MyDetails;
