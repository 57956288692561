import { navigate } from 'gatsby';
import React from 'react';
import { Col, Row, Container } from 'react-grid-system';
import styled from 'styled-components';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import Routes from '../../services/routes';
import Button from '../atoms/button';
import Divider from '../atoms/divider';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import ReceiptRow from '../molecules/ReceiptRow';

const ContainerReceipts = styled.div`
  height: 265px;
  overflow: auto;
  padding-right: 10px;
  white-space: nowrap;
`;

const HeaderTypo = styled(Typography)`
  font-weight: bold;
`;
const HeaderPrice = styled(Typography)`
  font-weight: bold;
`;
const PaymentMethod: React.FC = () => {
  const { receipts, storageAmount } = useFirebaseContext();
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <Container>
      <Typography variant="h3" color="primary">
        Betalningshistorik
      </Typography>
      <Spacer spacing={0.5} />
      <Divider color="placeholderPrimary" />
      <Spacer />
      <Col>
        <ContainerReceipts>
          {receipts && receipts.length > 0 && (
            <Row style={{ minWidth: 1200 }}>
              <Col>
                <HeaderTypo>Datum</HeaderTypo>
              </Col>
              <Col>
                <HeaderTypo>Betal-ID</HeaderTypo>
              </Col>
              <Col>
                <HeaderTypo>Typ</HeaderTypo>
              </Col>
              <Col>
                <HeaderPrice align="end">Pris</HeaderPrice>
              </Col>
              <Col>
                <HeaderPrice align="end">Moms</HeaderPrice>
              </Col>
            </Row>
          )}
          <Spacer />
          {receipts && receipts.length > 0 ? (
            receipts.map((receipt) => (
              <React.Fragment key={receipt.id}>
                <ReceiptRow {...receipt} />
                <Spacer />
              </React.Fragment>
            ))
          ) : (
            <>
              <Typography variant="h4" color="drawerBackground">
                Betalningshistorik saknas...
              </Typography>
              <Spacer spacing={4} />
            </>
          )}
        </ContainerReceipts>
      </Col>
      <Spacer spacing={4} />
      <Row>
        <Col xs={12} md="content">
          <Button
            disabled={storageAmount === 0}
            onClick={() => navigate(Routes.SUBSCRIPTION)}
          >
            Avsluta panBox
          </Button>
        </Col>
        <Col xs={12} md="content">
          {IS_MOBILE && <Spacer />}
          <Button
            primary
            disabled={storageAmount === 0}
            onClick={() => navigate(Routes.PAYMENT_DETAILS)}
          >
            Ändra betalningsuppgifter
          </Button>
        </Col>
      </Row>
      <Spacer spacing={3} />
    </Container>
  );
};

export default PaymentMethod;
