import React from 'react';
import { Col, Row } from 'react-grid-system';
import Container from '../atoms/container';
import EndSubscriptionForm from '../organisms/endSubscriptionForm';

const SubscriptionPage = () => {
  return (
    <Container>
      <Row>
        <Col>
          <EndSubscriptionForm backgroundColor="formBackground" />
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionPage;
