import React from 'react';
import { Col, Row } from 'react-grid-system';
import { MyDetailsFormSchema } from '../../services/validationSchema';
import { Form, Formik, Field, FieldProps } from 'formik';
import TextInput from '../molecules/textInput';
import Button from '../atoms/button';
import Spacer from '../atoms/spacer';
import { ColorType } from '../atoms/interfaces';
import styled from 'styled-components';
import { myDetailsFormFields } from '../../services/formData';
import { IMyDetailsFormValues } from '../pages/myDetails';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
interface IFormWrapper {
  backgroundColor: ColorType;
}

export interface IMyDetailsForm {
  formData: IMyDetailsFormValues;
  ctaTitle: string;
  loadingData: boolean;
  backgroundColor: ColorType;
  onSubmit: (values: IMyDetailsFormValues) => void;
}

const FormWrapper = styled.div<IFormWrapper>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.textPrimary};
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
`;

const MyDetailsForm: React.FC<IMyDetailsForm> = (props) => {
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <Formik
      initialValues={props.formData}
      validationSchema={MyDetailsFormSchema}
      enableReinitialize
      onSubmit={props.onSubmit}
    >
      {() => (
        <Form>
          <FormWrapper backgroundColor={props.backgroundColor}>
            <Row>
              {myDetailsFormFields.map((item) => (
                <Col key={item.name} md={6}>
                  <Field name={item.name}>
                    {({ field, meta }: FieldProps) => (
                      <TextInput
                        {...field}
                        {...item}
                        errorMsg={meta.error ? meta.error : undefined}
                      />
                    )}
                  </Field>
                  <Spacer />
                </Col>
              ))}
              <StyledButton
                isMobile={IS_MOBILE}
                primary
                isLoading={props.loadingData}
                type="submit"
              >
                {props.ctaTitle}
              </StyledButton>
            </Row>
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default MyDetailsForm;
