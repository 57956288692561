import React, { useState } from 'react';
import GlobalStyles from '../styles/global';

import Footer from './organisms/footer';
import { Col, Container, Row, Hidden } from 'react-grid-system';
import NavigationDrawer from './organisms/navigationDrawer';
import Routes, {
  drawerLinks,
  footerLinks,
  menuLinks
} from '../services/routes';
import styled, { css, keyframes } from 'styled-components';
import { facebookUrl } from '../constants';

import FacebookRoundLogo from '../images/icons/facebook-round.png';

//PanBox logotypes
import logoWhite from '../images/panBox-logo-white.svg';
import mobileLogo from '../images/panBox-logo-green.svg';

import { ISocialImageLink } from './atoms/interfaces';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import Header from './organisms/header';
import MyPagesMenu from './organisms/myPagesMenu';
import Spacer from './atoms/spacer';
import SEO from './seo';
import { FULL_PAGE_HEIGHT } from '../services/sizeHelper';
import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import useFirebaseContext from '../hooks/useFirebaseContext';

const socialImages: ISocialImageLink[] = [
  { href: facebookUrl, src: FacebookRoundLogo }
];

interface IMobile {
  ismobile: string;
  shouldShowNavBar?: boolean;
}

const StyledRow = styled(Row)`
  min-height: ${FULL_PAGE_HEIGHT};
`;

const ContainerContent = styled.div<IMobile>`
  padding-right: 1rem;
  ${({ ismobile }) =>
    ismobile === 'true' &&
    css`
      padding-right: 0.5rem;
    `}
  ${({ shouldShowNavBar }) =>
    !shouldShowNavBar &&
    css`
      padding-right: 0;
    `}
`;

const StyledContainer = styled(Container)<IMobile>`
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: ${({ theme }) => theme.colors.pageBackground};
`;

const ContainerNavBar = styled.div`
  width: 452px;
`;

const fadeIn = keyframes`
  from {transform: translateX(700px); opacity: 0;}
  to { transform: translateX(0); opacity: 1;}
`;
const fadeOut = keyframes`
  from {transform: translateX(0); opacity: 1; }
  to { transform: translateX(700px); opacity: 0; }
`;

const MenuWrapper = styled.div<{ open: boolean }>`
  z-index: 999;
  position: fixed;
  height: 100%;
  right: 0;
  display: none;
  ${({ open }) =>
    open
      ? css`
          display: block;
          animation: ${fadeIn} 0.5s forwards;
        `
      : css`
          animation: ${fadeOut} 0.5s forwards;
        `}
`;

const PrivateLayout: React.FC = (props) => {
  const [open, setOpen] = useState(false);
  const { IS_MOBILE } = useScreenClassHelper();
  const [shouldShowNavBar, setShouldShowNavBar] = useState(true);
  const location = useLocation();
  const { fetchData } = useFirebaseContext();

  useEffect(() => {
    if (location.pathname === Routes.ORDER_HOME_PAYMENT) {
      setShouldShowNavBar(false);
    } else if (!shouldShowNavBar) {
      setShouldShowNavBar(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <SEO />
      <GlobalStyles />
      <Header image={IS_MOBILE ? mobileLogo : logoWhite} navLinks={menuLinks} />
      {open && shouldShowNavBar && (
        <MenuWrapper open={open}>
          <NavigationDrawer
            closeDrawer={() => setOpen(false)}
            linkItems={drawerLinks}
            logo={logoWhite}
          />
        </MenuWrapper>
      )}
      <StyledContainer
        shouldShowNavBar={shouldShowNavBar}
        fluid
        ismobile={IS_MOBILE.toString()}
      >
        <StyledRow nowrap>
          <Hidden xs sm>
            {shouldShowNavBar && (
              <ContainerNavBar>
                <NavigationDrawer
                  closeDrawer={() => {}}
                  logo={logoWhite}
                  linkItems={drawerLinks}
                />
              </ContainerNavBar>
            )}
          </Hidden>
          <Col>
            {IS_MOBILE && <MyPagesMenu onClick={() => setOpen(!open)} />}
            {shouldShowNavBar && <Spacer spacing={IS_MOBILE ? 2 : 10} />}
            <ContainerContent ismobile={IS_MOBILE.toString()}>
              {props.children}
            </ContainerContent>
          </Col>
        </StyledRow>
      </StyledContainer>
      <Footer
        image={logoWhite}
        socialImages={socialImages}
        navLinks={footerLinks}
      />
    </>
  );
};

export default PrivateLayout;
