import React from 'react';
import { Col, Row } from 'react-grid-system';
import { IReceiptData } from '../../context/FirebaseContext';
import dayjs from 'dayjs';
import Typography from '../atoms/typography';
import { formatPrice } from '../../services/prices';
import { PAYMENT_TYPE } from '../../utils/localStorage';

const getPaymentTypeString = (paymentType: PAYMENT_TYPE) => {
  switch (paymentType) {
    case PAYMENT_TYPE.DEFAULT_ORDER:
      return 'panBox + beredskapslager';
    case PAYMENT_TYPE.DEFAULT_ORDER_NO_STORAGE:
      return 'panBox';
    case PAYMENT_TYPE.ORDER_HOME:
      return 'Fraktkostnad';
    case PAYMENT_TYPE.ORDER_HOME_RESTOCK:
      return 'Fraktkostnad + fylla på panBox';
    case PAYMENT_TYPE.END_SUBSCRIPTION:
      return 'panBox - avslut av abonnemang';
    case PAYMENT_TYPE.SUBSCRIPTION:
      return 'Månadskostnad för beredskapslager';
    default:
      return '';
  }
};

const ReceiptRow: React.FC<IReceiptData> = (receipt) => (
  <Row style={{ minWidth: 1200 }}>
    <Col>
      <Typography>{dayjs(receipt.created).format('YYYY-MM-DD')}</Typography>
    </Col>
    <Col>
      <Typography>{receipt.number}</Typography>
    </Col>
    <Col>
      <Typography>{getPaymentTypeString(receipt.type)}</Typography>
    </Col>
    <Col>
      <Typography align="end">{formatPrice(receipt.amount / 100)}</Typography>
    </Col>
    <Col>
      <Typography align="end">
        {formatPrice((receipt.amount / 100) * 0.25, true)}
      </Typography>
    </Col>
  </Row>
);

export default ReceiptRow;
