import React from 'react';
import { Router } from '@reach/router';
import Dashboard from '../components/pages/dashboard';
import MyDetails from '../components/pages/myDetails';
import OrderHome from '../components/pages/orderHome';
import PaymentMethod from '../components/pages/paymentMethod';
import PrivateRoute from '../components/privateRoute';
import Routes from '../services/routes';
import PrivateLayout from '../components/privateLayout';
import OrderHomePayment from '../components/pages/orderHomePayment';
import Subscription from '../components/pages/subscription';
import PaymentDetailsPage from '../components/pages/paymentDetails';

const MyPages = () => (
  <PrivateLayout>
    <Router>
      <PrivateRoute path={Routes.DASHBOARD} component={Dashboard} />
      <PrivateRoute path={Routes.ORDER_HOME} component={OrderHome} />
      <PrivateRoute path={Routes.MY_DETAILS} component={MyDetails} />
      <PrivateRoute path={Routes.PAYMENT_METHOD} component={PaymentMethod} />
      <PrivateRoute
        path={Routes.ORDER_HOME_PAYMENT}
        component={OrderHomePayment}
      />
      <PrivateRoute path={Routes.SUBSCRIPTION} component={Subscription} />
      <PrivateRoute
        path={Routes.PAYMENT_DETAILS}
        component={PaymentDetailsPage}
      />
    </Router>
  </PrivateLayout>
);

export default MyPages;
