import dayjs from 'dayjs';
import { navigate } from 'gatsby-link';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { SEAMLESS_VIEW_ID } from '../../constants';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import usePayment from '../../hooks/usePayment';
import {
  PAYMENT_TYPE,
  resetPaymentDetails,
  savePaymentType
} from '../../utils/localStorage';
import Button from '../atoms/button';
import Container from '../atoms/container';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';

const HintTypography = styled(Typography)`
  font-size: 0.8rem;
`;

export const ContainerSeamless = styled.div<{ show: boolean }>`
  ${({ show }) =>
    show &&
    `
padding: 1rem;
border-radius: 5px;
background-color: white;
`}
`;

const PaymentDetailsPage = () => {
  const { receipts } = useFirebaseContext();
  const { initiatePayment, startPayment, isPaymentLoading } = usePayment();

  const [showPaymentSection, setShowPaymentSection] = useState(false);

  useEffect(() => {
    resetPaymentDetails();
  }, []);

  useEffect(() => {
    if (showPaymentSection) {
      startPayment();
    }
  }, [showPaymentSection]);

  const handleContinueClick = async () => {
    savePaymentType(PAYMENT_TYPE.RESERVE);
    setShowPaymentSection(true);
    const res = await initiatePayment({ amount: 100 });
    setShowPaymentSection(res);
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const lastUpdate = useMemo(() => {
    const receipt = receipts?.find(
      (receipt) => receipt.type === PAYMENT_TYPE.DEFAULT_ORDER
    );
    if (receipt) {
      return dayjs(receipt.created).format('YYYY-MM-DD');
    }
  }, [receipts]);

  return (
    <Container>
      <Typography variant="h3" color="primary">
        Ändra betalningsuppgifter
      </Typography>
      <Spacer />
      <Typography>
        Uppdatera dina kortuppgifter om du har bytt kort eller om en
        abonnemangsavgift betalning misslyckats.
      </Typography>
      <Spacer />
      {lastUpdate && (
        <HintTypography>{`Senast uppdaterat: ${lastUpdate}`}</HintTypography>
      )}
      <Spacer spacing={3} />
      <ContainerSeamless show={showPaymentSection}>
        <div id={SEAMLESS_VIEW_ID} />
      </ContainerSeamless>
      {showPaymentSection && <Spacer spacing={3} />}
      <Row>
        <Col xs={12} md="content">
          <Button onClick={handleCancelClick}>Avbryt</Button>
        </Col>
        <Col xs={12} md="content">
          <Button
            primary
            onClick={handleContinueClick}
            isLoading={isPaymentLoading}
          >
            Okej
          </Button>
        </Col>
      </Row>
      <Spacer />
      <HintTypography>
        Ett belopp på 1 kr reseveras och återbetalas när kortuppgifterna har
        uppdaterats.
      </HintTypography>
    </Container>
  );
};

export default PaymentDetailsPage;
