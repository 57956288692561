import React from 'react';
import Typography from '../atoms/typography';
import Spacer from '../atoms/spacer';
import Button from '../atoms/button';
import { Col, Row, Container } from 'react-grid-system';
import styled from 'styled-components';

import panbox from '../../images/panbox.png';
import OrderDetails from '../molecules/orderDetails';
import Prices from '../../services/prices';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import Routes from '../../services/routes';
import { navigate } from 'gatsby';

const Image = styled.img`
  width: 100%;
  margin: auto;
`;

const HeaderAmount = styled(Typography)`
  font-weight: bold;
`;

const Dashboard: React.FC = () => {
  const { storageAmount, productAmount } = useFirebaseContext();

  const handleOrderClick = () => {
    navigate(Routes.ORDER);
  };

  const orderDetails = [
    {
      title: `${storageAmount} x Avgift beredskapslager* á:`,
      price: Prices.SUBSCRIPTION,
      amount: storageAmount
    }
  ];

  return (
    <Container>
      <Row>
        <Col>
          <Typography color="primary" variant="h3">
            Min panbox
          </Typography>
          <Spacer />
          <Typography>
            Du har{' '}
            <HeaderAmount color="error" variant="span">
              {productAmount} st
            </HeaderAmount>{' '}
            panBox
          </Typography>
        </Col>
      </Row>
      <Spacer spacing={3} />
      <Row>
        {storageAmount > 0 ? (
          <>
            <Col md={6} xs={12}>
              <Image alt="tjej" src={panbox} />
            </Col>
            <Col md={6} xs={12}>
              <OrderDetails
                orderDetails={orderDetails}
                disclaimer="*Månadsavgift för hyllplats beredskapslager."
                onClick={handleOrderClick}
              />
            </Col>
          </>
        ) : (
          <Col>
            <Spacer spacing={3} />
            <Typography variant="h3">
              Det finns ingen panBox i ditt beredskapslager
            </Typography>
            <Spacer />
            <Button onClick={handleOrderClick}>Boka nu!</Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
